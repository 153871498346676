<template>
  <div
    :id="widgetId"
    @drop="onDropOnWidgets($event, 1)"
    @contextmenu="this.setContextIdWidget"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      paddingTop: '5px',
      background: this.getBackgroundColor(),
      transition: 'var(--transition)',
      borderWidth: this.borderDisplay ? this.borderWidth + 'px' : '0px',
      'border-color': this.borderColor,
      overflow: 'hidden',
    }"
    class="bg_purple shadow-lg"
    style="position: relative"
    @mouseover="HoverOnWidget"
    @mouseout="HoverOutOnWidget"
  >
    <div class="transitionN numeric_pop" style="display: none">
      <span class="">{{ this.displayName || this.fullName }} </span>
    </div>
    <div class="text-center eventsnonePopup numericContainer">
      <div
        class="uppercase tracking-wide text-sm font-semibold text-md"
        :style="{
          'text-overflow': 'ellipsis',
        }"
        :class="[
          { threeDots: showTruncate },
          { __theme_color_title: !this.titleColor },
        ]"
      >
        <p
          v-if="titleDisplay"
          :style="{
            fontSize: isDragStarted
              ? calculatingFontSize()
              : !defalutFsize &&
                (this.autoFit
                  ? this.widgetWidth < this.widgetHeight
                    ? this.widgetWidth * 1.6 + '%'
                    : this.widgetHeight * 1.6 + '%'
                  : this.titleSize * 3 + '%'),
            background: this.titleBgColor,
            color: this.titleColor,
            display: this.titleDisplay,
            lineHeight: '1.4',
            fontWeight: '600',
            paddingBottom:
              this.numericValue != undefined || numericValue != null
                ? '0px'
                : '0px',
          }"
          class="text-md font-thin numaricTitle"
        >
          {{ this.displayName || this.fullName }}
        </p>
      </div>
      <div
        class="flex justify-center items-center"
        :class="[
          {
            __theme_color_title: !this.valueColor,
          },
        ]"
      >
        <p
          :style="{
            fontSize: isDragStarted
              ? calculatingFontSizeOfNumaricValue()
              : !defalutFsize &&
                (this.autoFit
                  ? this.widgetWidth < this.widgetHeight
                    ? this.widgetWidth * 1.5 + '%'
                    : this.widgetHeight * 1.6 + '%'
                  : this.valueSize * 3 + '%'),
            color: darkDark !== 'white' ? this.valueColor : this.valueColor,
            display: this.valueDisplay,
            paddingTop: '0px',
          }"
          class="numericValue text-md"
          v-if="this.valueDisplay"
        >
          {{
            isNaN(parseFloat(numericValue))
              ? parseFloat(0).toFixed(this.decimalValue)
              : parseFloat(numericValue).toFixed(this.decimalValue)
          }}
        </p>
        &nbsp;
        <small
          v-if="this.widgetUnitL && this.unitDisplay"
          class="numericValueUnit text-md flex items-center"
          :class="[{ __theme_color_unit: !this.unitColor }]"
          :style="{
            fontSize: isDragStarted
              ? calculatingFontSizeNumaricUnit()
              : !defalutFsize &&
                (this.autoFit
                  ? this.widgetWidth < this.widgetHeight
                    ? this.widgetWidth * 1.5 + '%'
                    : this.widgetHeight * 1.2 + '%'
                  : this.unitSize * 3 + '%'),
            color: darkDark !== 'white' ? unitColor : unitColor,
            display: this.unitDisplay,
            lineHeight: '0.5',
            textTransform: 'uppercase',
          }"
        >
          {{ this.widgetUnitL }}
        </small>
      </div>
    </div>
    <p class="hidden">{{ this.darkDark }}</p>
  </div>
</template>

<script>
import apiService from "../../../api/services";
import { useReplay } from "../../../composable/useReplay.js";
import helperServices from "../../../helper-services";
let id = null;

var data = {
  soundurl: "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3",
};

export default {
  name: "numeric-widget",

  components: {},

  props: {
    // sockets: Object,
    blinkAlert: Boolean,
    index: [Number, String],
    borderWidth: {
      type: [Number, String],
      default: "1",
    },
    autoFit: {
      type: Boolean,
      default: true,
    },
    borderDisplay: {
      type: Boolean,
      default: false,
    },
    // borderColor: {
    //   type: [Number, String],
    //   default: "#FFFFFF",
    // },
    alertStartRange: {
      type: [Number, String],
      default: Number.NEGATIVE_INFINITY,
    },
    alertEndRange: {
      type: [Number, String],
      default: Number.POSITIVE_INFINITY,
    },
    decimalValue: {
      type: [Number, String],
      default: 2,
    },
    alertColo: {
      type: [Number, String],
      default: "#ff0000",
    },
    cardBackground: {
      type: [Number, String],
      default: "",
    },
    titleDisplay: {
      type: Boolean,
      default: true,
    },
    titleSize: {
      type: [Number, String],
      default: "2",
    },
    titleColor: {
      type: [Number, String],
      default: null,
    },
    titleBgColor: {
      type: [Number, String],
      default: "",
    },
    title: {
      type: [Number, String],
      default: "",
    },
    fullName: {
      type: [Number, String],
      default: "",
    },
    valueDisplay: {
      type: Boolean,
      default: true,
    },
    valueSize: {
      type: [Number, String],
      default: "32",
    },
    valueColor: {
      type: [Number, String],
      // default: "#fffafa",
    },
    valueBgColor: {
      type: [Number, String],
      default: "",
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    unitBgColor: {
      type: [Number, String],
      default: "",
    },
    unitDisplay: {
      type: Boolean,
      // default: true,
    },
    unitSize: {
      type: [Number, String],
      default: "30",
    },
    unitColor: {
      type: [Number, String],
      // default: "#fffafa",
    },
    widgetHeight: {
      type: [Number, String],
      default: 200,
    },
    widgetWidth: {
      type: [Number, String],
      default: 350,
    },
    widgetTitle: {
      type: [Number, String],
      default: "Title",
    },
    widgetValue: {
      type: [Number, String],
      default: "Value",
    },
    widgetUnitL: {
      type: [Number, String],
      default: "",
    },
    openConfig: Function,
    height: [Number, String],
    width: [Number, String],
    displayId: [Number, String],
    widgetId: [Number, String],
    isDragStarted: Boolean,
    isReplay: {
      type: Boolean,
      default: false,
    },
    unit_conversion_factor: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      borderColor: "#FFFFFF",
      max: "",
      defalutFsize: false,
      callingCalc: true,
      intialFontSize: "",
      intialFontSizeNumericValue: "",
      originalWidgetWidth: this.widgetWidth,
      originalWidgetHeight: this.widgetHeight,
      ele: "",
      heightOfNumPop: "",
      showTruncate: true,
      alertBlinker: null,
      tooltipValue: "",
      cardWidgetConfig: this.numericWidgetConfig,
      isPlayed: false,
      alertColor: this.alertColo,
      isBlinkAlert: false,
      properties: {},
      tag: null,
      displayChanged: false,
      numericValue: "",
      wellId: "",
      wellboreId: "",
      logId: "",
      status: "",
      defaultTitle: "",
      logType: "",
      logTypeId: "",
      widgetUnit: this.widgetUnitL,
      table: null,
      socket: null,
      displayName: "",
      topic: null,
      fields: [],
      newId: null,
      uniqueId: null,
      defaultBackgroundColor: "#3f3f3f",
      numericValueOriginal: "",
    };
  },
  created() {},
  unmounted() {
    this.sockets.unsubscribe(this.$clientId);
  },
  beforeUnmount() {
    let topic = this.topic;

    if (this.$socket) {
      let tempId = this.widgetId;

      this.$socket.emit("unsubscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        id: tempId,
        clientId: this.$clientId,
      });

      this.sockets.unsubscribe(this.$clientId);
    }
  },
  beforeMount() {
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];

    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      this.table = displayDtls.table;
      this.numericValue = "";
      this.tooltipValue = "";

      this.topic = displayDtls.topicName;
      this.time_zone = displayDtls.time_zone || "CST6CDT";
      try {
        this.max = new Date(displayDtls.max).toISOString("en-US", {
          timeZone: this.time_zone,
        });
      } catch (error) {}

      if (this.status == "UPDATING" || this.status == 0) {
        let topic = displayDtls.topicName;

        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        let timeout_var_livedata = setTimeout(() => {
          clearTimeout(timeout_var_livedata);
          this.getRealtimeData();
        }, 600);
      }
      let call_unit = async () => {
        try {
          let mapping = await helperServices.getIndexDetails(this.wellId);
          if (mapping) mapping = mapping.data.logs[this.logType];
          // console.log(" unit conversion : ", mapping);
          if (mapping) {
            this.widgetUnit = mapping.units[mapping.tags.indexOf(this.title)];

            this.$store.dispatch("rect/changeNumericUnit", {
              displayId: this.displayId,
              widgetId: this.widgetId,
              value: this.widgetUnit,
            });
          }
        } catch (error) {
          console.error("error for units : ", error);
        }
      };
      if (this.title && this.wellId) call_unit();
      // else {
      setTimeout(() => {
        this.getTagValue(displayDtls);
      }, 300);

      // }
    } else {
    }
  },
  mounted() {
    if (this.alertBlinker) {
      clearInterval(this.alertBlinker);
      this.alertColor = this.alertColo;
    }
    if (this.blinkAlert) {
      this.isBlinkAlert = true;
      let i = 0;
      // if (newVal) {
      this.alertBlinker = setInterval(() => {
        let color = [
          this.darkDark !== "white"
            ? this.cardBackground || this.defaultBackgroundColor
            : this.cardBackground || "#FFFFF7",
          this.alertColo,
        ];
        i = (i + 1) % color.length;
        this.alertColor = color[i];
        if (!this.isBlinkAlert) {
          clearInterval(this.isBlinkAlert);
          this.alertColor = this.alertColo;
        }
      }, 1000);
      // }
    }
    this.uniqueId = Math.random().toString(36).slice(2);
    this.newId = this.widgetId;
    let value;
    let valueAt = "end";
    if (!this.isReplay) {
      this.$store.subscribe((mutation, state) => {
        let title = this.defaultTitle || this.title;
        let name,
          plName = "";
        //
        switch (mutation.type) {
          case "data/setSingleValue":
            let display = this.$store.state.disp.displays[this.displayId];

            valueAt = "end";
            name =
              title +
              "-" +
              this.displayId +
              "-" +
              display.logId +
              "-" +
              valueAt;
            //
            plName =
              mutation.payload.title +
              "-" +
              mutation.payload.display +
              "-" +
              mutation.payload.logId +
              "-" +
              mutation.payload.valueAt;
            //
            if (name == plName) {
              // this.numericValue = mutation.payload.value;
            }
            break;

          case "data/displayChanged":
            //
            valueAt = "end";

            name =
              title + "-" + this.displayId + "-" + this.logId + "-" + valueAt;
            //
            if (name == mutation.payload) {
              this.numericValue = this.$store.state.data[name].value;
              //
            }
            break;

          case "disp/setDisplay":
            if (mutation.payload.display == this.displayId) {
              let topic = mutation.payload.topicName;
              this.topic = mutation.payload.topicName;
              this.time_zone = mutation.payload.time_zone || "CST6CDT";
              this.$socket.emit("unsubscribe", {
                logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                topic: this.topic,
                id: this.widgetId,
                clientId: this.$clientId,
              });

              this.wellId = mutation.payload.wellId;
              this.wellboreId = mutation.payload.wellboreId;
              this.logId = mutation.payload.logId;
              this.status = mutation.payload.status;
              this.logType = mutation.payload.logType;
              this.logTypeId = mutation.payload.logTypeId;
              this.time_zone = mutation.payload.time_zone || "CST6CDT";
              if (this.logType == "time") {
                this.max = new Date(mutation.payload.max).toISOString("en-US", {
                  timeZone: this.time_zone,
                });
              }

              this.table = mutation.payload.table;
              this.numericValue = "";
              this.tooltipValue = "";

              this.sockets.unsubscribe(this.$clientId);
              if (
                mutation.payload.status == "UPDATING" ||
                mutation.payload.status == 0
              ) {
                setTimeout(this.getRealtimeData(), 500);
              }

              // console.log(" unit conversion : ", this.title);
              let call_unit = async () => {
                try {
                  let mapping = await helperServices.getIndexDetails(
                    this.wellId
                  );
                  if (mapping) mapping = mapping.data.logs[this.logType];
                  // console.log(" unit conversion : ", mapping);
                  if (mapping) {
                    this.widgetUnit =
                      mapping.units[mapping.tags.indexOf(this.title)];

                    this.$store.dispatch("rect/changeNumericUnit", {
                      displayId: this.displayId,
                      widgetId: this.widgetId,
                      value: this.widgetUnit,
                    });
                  }
                } catch (error) {
                  console.error("error for units : ", error);
                }
              };
              if (this.title) call_unit();
              // else {
              setTimeout(() => {
                this.getTagValue();
              }, 300);
              // }
            }
            break;

          // case "disp/setTags":
          //   if (mutation.payload.details.display == this.displayId) {
          //     this.widgetUnit =
          //       this.$store.state.disp.displays[this.displayId].units[
          //         this.$store.state.disp.displays[this.displayId].tags.indexOf(
          //           this.title
          //         )
          //       ];
          //   }
          //   break;
          case "disp/MINIMIZE_DISP":
            if (mutation.payload.displayId == this.displayId) {
              console.log(
                "Numeric minimize",
                `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`
              );
              this.$socket.emit("unsubscribe", {
                logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                topic: this.topic,
                id: this.widgetId,
                clientId: this.$clientId,
              });
            }
            break;
          case "disp/MAXIMIZE_DISP":
            if (mutation.payload.displayId == this.displayId) {
              this.$socket.emit("subscribe", {
                logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                topic: this.topic,
                fields: this.fields,
                id: this.widgetId,
                clientId: this.$clientId,
              });
            }
            break;

          default:
            //
            break;
        }
      });
    }
  },
  computed: {
    darkDark() {
      if (this.$store.state.rect.darkmode !== "white")
        this.borderColor = "#FFFFFF";
      else this.borderColor = "#000000";
      console.log(this.borderColor);
      return this.$store.state.rect.darkmode;
    },
  },
  sockets: {
    connect: function () {},
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
    },
  },
  methods: {
    getBackgroundColor() {
      return this.blinkAlert &&
        (parseFloat(this.alertStartRange) >= parseFloat(this.numericValue) ||
          parseFloat(this.alertEndRange) <= parseFloat(this.numericValue))
        ? this.alertColor || "#ff00000"
        : this.darkDark !== "white"
        ? this.cardBackground || "var(--navBar2Bg)"
        : this.cardBackground || "#FFFFF7";

      // this.defaultBackgroundColor
    },
    calculatingFontSizeNumaricUnit() {
      this.defalutFsize = true;
      let calculatedFontSize;

      let commonConditionWidth = this.originalWidgetWidth < this.widgetWidth;
      let commonConditionHeight = this.originalWidgetHeight < this.widgetHeight;
      let intialCondition =
        this.widgetHeight === this.originalWidgetHeight &&
        this.widgetWidth === this.originalWidgetWidth;
      if (this.autoFit) {
        // intial font size before dragging
        if (this.widgetWidth > this.widgetHeight && intialCondition) {
          calculatedFontSize = this.widgetHeight * 1.4 + "%";

          this.intialFontSize = calculatedFontSize;
        }
        // font size when width and height both are incressed
        else if (
          this.widgetWidth > this.originalWidgetWidth &&
          this.widgetHeight > this.originalWidgetHeight &&
          this.widgetWidth > this.widgetHeight
        ) {
          calculatedFontSize = this.widgetHeight * 1.4;
          calculatedFontSize =
            calculatedFontSize > 100
              ? 100 + ((calculatedFontSize - 100) * 8) / 10 + "%"
              : this.widgetHeight * 1.4 + "%";
        }
        // font size only width is incressed
        else if (
          commonConditionWidth &&
          this.widgetHeight === this.originalWidgetHeight
        ) {
          calculatedFontSize = this.intialFontSizeNumericValue - 27 + "%";
        }
        // font size only height is incressed
        else if (
          commonConditionHeight &&
          this.widgetWidth === this.originalWidgetWidth
        ) {
          calculatedFontSize = (this.widgetHeight * 1) / 4 + 64;
          calculatedFontSize =
            calculatedFontSize > 140
              ? 140 + "%"
              : (this.widgetHeight * 1) / 4 + 64 + "%";
        }
      }
      // font size when auto fit is false
      else {
        calculatedFontSize = this.unitSize * 3 + "%";
      }

      return calculatedFontSize;
    },
    calculatingFontSizeOfNumaricValue() {
      this.defalutFsize = true;

      let calculatedFontSize;

      let commonConditionWidth = this.originalWidgetWidth < this.widgetWidth;
      let commonConditionHeight = this.originalWidgetHeight < this.widgetHeight;
      let intialCondition =
        this.widgetHeight === this.originalWidgetHeight &&
        this.widgetWidth === this.originalWidgetWidth;
      if (this.autoFit) {
        // intial font size before dragging
        if (this.widgetWidth > this.widgetHeight && intialCondition) {
          calculatedFontSize = this.widgetHeight * (16 / 8);
        }
        // font size when width and height both are incressed
        else if (
          this.widgetWidth > this.originalWidgetWidth &&
          this.widgetHeight > this.originalWidgetHeight &&
          this.widgetWidth > this.widgetHeight
        ) {
          calculatedFontSize = this.widgetHeight * (16 / 8);
          calculatedFontSize =
            calculatedFontSize > 100
              ? 100 + ((calculatedFontSize - 100) * 8) / 10
              : this.widgetHeight * (16 / 8);
        }
        // font size only width is incressed
        else if (
          commonConditionWidth &&
          this.widgetHeight === this.originalWidgetHeight
        ) {
          calculatedFontSize = (this.widgetWidth * 7) / 20 + 20;
          calculatedFontSize =
            calculatedFontSize > 100
              ? 100 + ((calculatedFontSize - 100) * 1) / 10
              : (this.widgetWidth * 7) / 20 + 20;
        }
        // font size only height is incressed
        else if (
          commonConditionHeight &&
          this.widgetWidth === this.originalWidgetWidth
        ) {
          calculatedFontSize = (this.widgetHeight * 1) / 4 + 64;
          calculatedFontSize =
            calculatedFontSize > 140 ? 140 : (this.widgetHeight * 1) / 4 + 64;
        }
      }
      // font size when auto fit is false
      else {
        calculatedFontSize = this.valueSize * 3;
        // this.unitSize * 1 + '%'
      }
      this.intialFontSizeNumericValue = calculatedFontSize;

      if (this.numericValue) {
        this.numericValue.length >= 9
          ? (calculatedFontSize =
              calculatedFontSize > 100 && 100 + (calculatedFontSize - 100) / 5)
          : (calculatedFontSize = calculatedFontSize);
      }
      return calculatedFontSize + "%";
    },
    calculatingFontSize() {
      this.defalutFsize = true;

      let calculatedFontSize;

      let commonConditionWidth = this.originalWidgetWidth < this.widgetWidth;
      let commonConditionHeight = this.originalWidgetHeight < this.widgetHeight;
      let intialCondition =
        this.widgetHeight === this.originalWidgetHeight &&
        this.widgetWidth === this.originalWidgetWidth;

      if (this.autoFit) {
        // intial font size before dragging
        if (this.widgetWidth > this.widgetHeight && intialCondition) {
          calculatedFontSize = this.widgetHeight * (16 / 9) + "%";
          let intialFS = this.widgetHeight * (16 / 9) + "%";
          this.intialFontSize = intialFS;
        }
        // font size when width and height both are incressed
        else if (
          this.widgetWidth > this.originalWidgetWidth &&
          this.widgetHeight > this.originalWidgetHeight &&
          this.widgetWidth > this.widgetHeight
        ) {
          calculatedFontSize = this.widgetHeight * (16 / 9);
          calculatedFontSize =
            calculatedFontSize > 100
              ? 100 + ((calculatedFontSize - 100) * 8) / 10 + "%"
              : this.widgetHeight * (16 / 9) + "%";
        }
        // font size only width is incressed
        else if (
          commonConditionWidth &&
          this.widgetHeight === this.originalWidgetHeight
        ) {
          calculatedFontSize = (this.widgetWidth * 7) / 20 + 20;
          calculatedFontSize =
            calculatedFontSize > 100
              ? 100 + ((calculatedFontSize - 100) * 1) / 10 + "%"
              : (this.widgetWidth * 7) / 20 + 20 + "%";
        }
        // font size only height is incressed
        else if (
          commonConditionHeight &&
          this.widgetWidth === this.originalWidgetWidth
        ) {
          calculatedFontSize = (this.widgetHeight * 1) / 4 + 70;
          calculatedFontSize =
            calculatedFontSize > 150
              ? 150 + "%"
              : (this.widgetHeight * 1) / 4 + 70 + "%";
        }
      }
      // font size when auto fit is false
      else {
        calculatedFontSize = this.titleSize * 3 + "%";
      }

      return calculatedFontSize;
    },
    HoverOutOnWidget(event) {
      event.target.classList.remove("addedClass");
    },
    HoverOnWidget(event) {
      event.preventDefault();

      let mEle = event.target;
      mEle.classList.add("addedClass");

      this.heightOfNumPop = mEle.children[0]?.offsetHeight + 5;
      if (mEle.firstChild.style)
        mEle.firstChild.style.top = "-" + this.heightOfNumPop + "px";
    },
    isNumeric(num) {
      return (
        (typeof num === "number" ||
          (typeof num === "string" && num.trim() !== "")) &&
        !isNaN(num)
      );
    },
    knowIsItNumOrString() {
      this.isNotANum = this.isNumeric(this.numericValue);

      return this.numericValue;
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.preventDefault()
      // e.stopPropagation();
    },
    processResult(sResult) {},
    getRealtimeData(title) {
      let test = this.widgetId;
      let topic = this.topic;

      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = [title || this.title];

        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];

        // if (this.logId) {
        this.$socket.emit("subscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: topic,
          fields,
          id: this.widgetId,
          clientId: this.$clientId,
          logType: this.logType,
        });
        if (!topicExists) {
          this.$store.dispatch("live/setSubscriptionFlag", {
            topic,
            flag: true,
          });
        }

        this.sockets.subscribe(this.$clientId, (sResult) => {
          let result = sResult[this.widgetId];

          if (result) {
            if (this.logType == "depth") {
              // this.tooltipValue = common["dmea"];
            }
            if (this.logType == "time") {
              // this.tooltipValue = new Date(
              //   parseInt(common["timestamp"])
              // ).toLocaleString();
            }

            let value = result[title] || result[this.title];
            if (value && value.length) {
              this.numericValue = value[value.length - 1];
              this.numericValueOriginal = value[value.length - 1];
              if (
                this.unit_conversion_factor &&
                this.unit_conversion_factor != ""
              ) {
                this.numericValue *= this.unit_conversion_factor;
              }
            } else {
              this.numericValue = 0;
              this.numericValueOriginal = 0;
            }
          }
        });

        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    onDropOnWidgets(event) {
      //
      event.stopPropagation();
      let onDropDtls = JSON.parse(event.dataTransfer.getData("elProps"));

      this.displayName = onDropDtls.fullName;
      this.defaultTitle = onDropDtls.title;
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.title,
        fullName: onDropDtls.fullName,
      });
      this.$store.dispatch("rect/changeNumericUnit", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.units,
      });
      let displayDtls = this.$store.state.disp.displays[this.displayId];

      // this.widgetTitle = onDropDtls.title;
      // this.widgetUnit = onDropDtls.units || "";
      this.numericValue = "";
      this.tooltipValue = "";
      if (this.status == "UPDATING") {
        this.sockets.unsubscribe(this.$clientId);
      }

      setTimeout(() => {
        this.getTagValue(displayDtls, onDropDtls.title);
      }, 300);
    },
    async getMnemonicType(mnemonic) {
      // let all_mnemonics =
      //   this.$store.state.disp.displays[this.displayId].mnemonics;

      // let display = this.$store.state.disp.selectedDisplay;
      // let displayobj = this.$store.state.disp.displays;
      let logDetails = await helperServices.getIndexDetails(this.wellId);
      let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
      const source_mnemonice = logDetails.data.logs[this.logType].source;
      const tags = logDetails.data.logs[this.logType].tags;
      let mnemonic_type = "UNPREFERRED";
      const mnemonicKey = tags.findIndex((t) => t == mnemonic);
      const actualKey = source_mnemonice[mnemonicKey];
      for (let i in all_mnemonics) {
        if (all_mnemonics[i][actualKey]) {
          mnemonic_type = i;
          break;
        }
      }
      return mnemonic_type;
    },
    async getTagValue(displayDtls, title) {
      if (this.max && title != "title" && this.title != "title") {
        let result = "";
        let mnemonic_type = await this.getMnemonicType(title || this.title);
        if (this.logType == "time")
          result = await apiService.WellServices.getSingleValueTimeLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: title || this.title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        else
          result = await apiService.WellServices.getSingleValueLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: title || this.title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        if (result != null) {
          this.numericValue = result.data[0]
            ? result.data[0][title || this.title]
            : "";
          this.numericValueOriginal = result.data[0]
            ? result.data[0][title || this.title]
            : "";

          if (this.logType === "depth") {
            this.tooltipValue = result.data[0]["dept"];
          }
          if (this.logType === "time") {
            this.tooltipValue = new Date(result.data[0]["time"]).toLocaleString(
              "en-US",
              {
                timeZone: this.time_zone,
              }
            );

            // this.$store.state.rect.toolTipforlastUpdatedTime.lastUpdatedTime = this.tooltipValue
          }
        }
      } else {
        this.numericValue = "";
        this.numericValueOriginal = "";
      }
      return this.numericValue;
    },
    getNumericValue(displayDtls) {
      let value;
      let valueAt = "end";
      //
      let titleValues = this.$store.state.data.tagValues[this.title];
      //
      if (titleValues != null) {
        for (let i = 0; i < titleValues.length; i++) {
          if (
            titleValues[i].wellId == displayDtls.wellId &&
            titleValues[i].wellboreId == displayDtls.wellboreId
          ) {
            let logs = titleValues[i].logs;
            for (let j = 0; j < logs.length; j++) {
              if ((logs[j].logId = displayDtls.logId)) {
                let logValues = logs[j].logValues;
                value = logValues[valueAt];

                break;
              }
            }
          }
        }
      }

      return value;
    },
    playSound() {
      this.isPlayed = !this.isPlayed;
      var audio = new Audio(data.soundurl);
      audio.play();
      setTimeout(() => {
        this.isPlayed = !this.isPlayed;
      }, 12000);
    },
    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
  },
  watch: {
    title(newVal) {
      let displayDtls = this.$store.state.disp.displays[this.displayId];
      this.defaultTitle = newVal;
      this.numericValue = "";
      this.tooltipValue = "";
      if (this.status == "UPDATING" || this.status == 0) {
        let topic = this.topic;

        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        this.getRealtimeData(newVal);
      }

      setTimeout(() => {
        this.getTagValue(displayDtls, newVal);
      }, 300);
    },
    fullName(newVal) {
      this.displayName = newVal;
    },
    blinkAlert: function (newVal) {
      this.isBlinkAlert = newVal;

      if (this.alertBlinker) {
        clearInterval(this.alertBlinker);
        this.alertColor = this.alertColo;
      }

      let i = 0;
      if (newVal) {
        this.alertBlinker = setInterval(() => {
          let color = [
            this.darkDark !== "white"
              ? this.cardBackground || this.defaultBackgroundColor
              : this.cardBackground || "#FFFFF7",
            this.alertColo,
          ];
          i = (i + 1) % color.length;
          this.alertColor = color[i];
          if (!this.isBlinkAlert) {
            clearInterval(this.isBlinkAlert);
            this.alertColor = this.alertColo;
          }
          console.log("alert color", this.alertColor);
        }, 1000);
      }
    },
    alertColo: async function (newVal, oldVal) {
      this.alertColor = newVal;
      if (this.alertBlinker) {
        clearInterval(this.alertBlinker);
        this.alertColor = newVal;
      }

      let i = 0;
      if (this.isBlinkAlert) {
        this.alertBlinker = setInterval(() => {
          let color = [
            this.darkDark !== "white"
              ? this.cardBackground || this.defaultBackgroundColor
              : this.cardBackground || "#FFFFF7",
            newVal,
          ];
          i = (i + 1) % color.length;
          this.alertColor = color[i];
          if (!this.isBlinkAlert && this.isBlinkAlert) {
            clearInterval(this.isBlinkAlert);
            this.alertColor = this.alertColo;
          }
        }, 1000);
      }
    },
    replayDetails(val) {
      let value = val[this.title];
      if (value && value.length) this.numericValue = value[value.length - 1];
      else this.numericValue = 0;
    },
    unit_conversion_factor(newVal, oldVal) {
      console.log("old val:", oldVal, " Newval:", newVal);
      if (newVal != "") {
        if (newVal == 1) {
          this.numericValue = JSON.parse(
            JSON.stringify(this.numericValueOriginal)
          );
        } else {
          this.numericValue *= newVal;
        }
      }
    },
    // widgetUnitL(newVal,oldVal){
    //   console.log('old val:',oldVal,' Newval:',newVal);
    //   if(newVal!='' && oldVal != newVal && this.unit_conversion_factor && this.unit_conversion_factor!=''){
    //     const mappedDet =this.numericValue * this.unit_conversion_factor;
    //   this.numericValue =mappedDet;
    //   }
    // }
  },
  setup() {
    const { isAuto, replayDetails } = useReplay();
    return {
      isAuto,
      replayDetails,
    };
  },
};
</script>

<style>


.typo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100px;
}

.nothing {
  display: inline-block;
  width: 100px;
}

.minimize {
  width: 0px;
  height: 0px;
  animation-name: animate-minimize;
  animation-duration: 2s;
  border: solid 1px;
}

.maximize {
  width: 400px;
  height: 400px;
  animation-name: animate-maximize;
  animation-duration: 2s;
  border: solid 1px;
}

/* .cust_font{
  font-family: 'Crimson Text', serif;
} */
.hoveringN {
  display: block;
  /* position: relative; */
  z-index: 99;
}

.transitionN {
  position: absolute;
  top: -40px;
  left: 0px;

  /* background: #000000d4; */

  font-size: 10px;
  /* opacity: 1; */
  z-index: 999;

  z-index: 999999;
  padding: 10px;
}

.tooltipN {
  /* display: block; */
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  text-align: center;
}

.__numeric_backgroud {
  background: var(--sidebarListBgColor) !important;
}

.numericDiv {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.numericDiv > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.numericDivHdng > p {
  font-weight: 600 !important;
  /* font-size: 11px !important; */
  line-height: 14px;
  /* color: var(--colorBoxHeadingText) !important; */
  text-align: left;
  padding: 0px !important;
}

.__theme_color_title {
  color: var(--colorBoxHeadingText) !important;
}

.__theme_color_value {
  color: var(--colorBoxWhiteNum) !important;
}

.__theme_color_unit {
  color: var(--colorBoxHeadingText) !important;
}

.numericDivHdng {
  width: 60%;
  margin-top: 0px !important;
}

.numericDivValues {
  /* width: 50%; */
}

.numericDivValues .numericValue {
  font-weight: 500;
  /* font-size: 12px !important; */
}

.numericDivValues .numericValueUnit {
  /* font-size: 9px !important; */
  font-weight: 600;
  /* margin-left: 6px !important; */
  letter-spacing: 1px;
}

.threeDots {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  */
}

.numeric_pop {
  background: #e5e5e5;
  color: #2a2a2a;
  display: flex;
  flex-direction: column;
  padding: 2px 10px !important;
  border-radius: 0px;
  text-align: center;
}

.eventsnonePopup {
  pointer-events: none;
}

.addedClass .transitionN {
  display: block !important;
  z-index: 99999 !important;
}

.__numeric__div {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.labelNumeric_NumericWidget div.numericContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
